import { Box, Container, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import JA, { API_URL } from "../../../api/api";
import AddAlertMessage from "../../../components/alert/Alert";
import ComplaintDetails from "../../../components/complaint-details/ComplaintDetails";
import Header from "../../../components/header/Header";
import { AppUtils } from "../../../utils/appUtils";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
import styles from "./style";

export default function Complaint(props) {
  const classes = styles();
  const [complaintData, setComplaintData] = useState([]);
  let ticketId = AppUtils.getUrlParam("id");

  useEffect(() => {
    ticketId &&
      getComplaintDetails();
  }, []);

  const getComplaintDetails = () => {
    JA.get(API_URL.complaintDetail + "/" + ticketId)
      .then(response => {
        setComplaintData(response.data);
      })
      .catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  };

  return (
    <Container maxWidth="xl" className={classes.root} disableGutters>
      <Header />
      <Box mb={1}>
        <Typography variant="h6">गुनासो/उजुरी विवरण</Typography>
      </Box>
      <Paper className={classes.complaintDetailsContainer}>
        <ComplaintDetails data={complaintData} getComplaintDetails={getComplaintDetails} />
      </Paper>
    </Container>
  );
}
