import PropTypes from "prop-types";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import { ROLE_ADMIN, ROLE_MUNICIPALITY_ADMIN, ROLE_MUNICIPALITY_REPRESENTATIVE, ROLE_WARD_REPRESENTATIVE, USER_ROLE } from "../utils/constants/index";
import { Cookies } from "../utils/storage/cookies";

export default function RouteWrapper({ component: Component, path: urlPath, isPrivate, isWrongLink, ...rest }) {
  if (isPrivate && !Cookies.readCookie(USER_ROLE)) {
    return <Redirect to="/" />;
  } else if (!isPrivate && !isWrongLink && !urlPath.includes("/user-not-authorized") && Cookies.readCookie(USER_ROLE) === ROLE_ADMIN) {
    return <Redirect to="/admin/dashboard" />;
  } else if (!isPrivate && !isWrongLink && !urlPath.includes("/user-not-authorized") && (Cookies.readCookie(USER_ROLE) === ROLE_MUNICIPALITY_ADMIN || Cookies.readCookie(USER_ROLE) === ROLE_MUNICIPALITY_REPRESENTATIVE || Cookies.readCookie(USER_ROLE) === ROLE_WARD_REPRESENTATIVE)) {
    return <Redirect to="/user/dashboard" />;
  } else if (isPrivate && urlPath.includes("/admin") && Cookies.readCookie(USER_ROLE) !== ROLE_ADMIN) {
    return <Redirect to="/user-not-authorized" />;
  } else {
    return <Route {...rest} component={Component} />;
  }
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  isWrongLink: PropTypes.bool
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  isWrongLink: false
};
