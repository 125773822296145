import { Box, Button, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import React, { useState } from "react";
import JA, { API_URL } from "../../api/api";
import AddAlertMessage from "../../components/alert/Alert";
import ComplaintDetails from "../../components/complaint-details/ComplaintDetails";
import MessageBlock from "../../components/message-block/MessageBlock";
import CustomModal from "../../components/modal/CustomModal";
import { AppMisc } from "../../misc/appMisc";
import { SOMETHING_WENT_WRONG } from "../../utils/constants";
import { DateUtils } from "../../utils/dateUtils";
import styles from "./style";

export default function ComplaintsList(props) {
  const [showComplaintDetailsModal, setShowComplaintDetailsModal] = useState(false);
  const [complaintData, setComplaintData] = useState([]);
  const classes = styles();

  const closeComplaintDetailsModal = () => {
    setShowComplaintDetailsModal(false);
  }

  function getComplaintDetails(id) {
    JA.get(API_URL.complaintDetail + "/" + id)
      .then(response => {
        setComplaintData(response.data);
        setShowComplaintDetailsModal(true);
      })
      .catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  }

  return (
    <>
      <Box className={classes.complaintsListContainer}>
        {
          props.complaints && props.complaints.length ? (
            <>
              <Grid container spacing={2} alignItems="center" className={classes.row}>
                {
                  props.complaints.map(complaint => (
                    <Grid className={classes.cardContainer} item xs key={complaint.id}>
                      <Card className={classes.card}>
                        <CardContent>
                          <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Box>
                              <Typography variant="caption">गुनासो/उजुरी प्रकृति</Typography>
                              <Typography color="primary">{AppMisc.getComplaintTypeLabel(complaint.complaintType)}</Typography>
                              <Typography variant="caption">{DateUtils.getDateFromMilliseconds(complaint.createdDate)}</Typography>
                            </Box>
                            <Button
                              startIcon={<Visibility />}
                              size="small"
                              color="primary"
                              variant="contained"
                              onClick={() => { getComplaintDetails(complaint.id) }}
                            >
                              विवरण हेर्नुहोस्
                            </Button>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))
                }
              </Grid>
            </>
          ) : (
              <MessageBlock text="माफ गर्नुहोस्, जन-आवाज प्रणालीमा हालसम्म कुनै पनि गुनासो/उजुरी दर्ता गरिएको छैन।" />
            )
        }
      </Box>
      <CustomModal
        title="गुनासो/उजुरी विवरण"
        showModal={showComplaintDetailsModal}
        onModalClose={closeComplaintDetailsModal}
        maxWidth="lg"
        hideSubmitBtn
        fullScreen
      >
        <ComplaintDetails data={complaintData} />
      </CustomModal>
    </>
  );
}
