import CssBaseline from "@material-ui/core/CssBaseline";
import React from "react";
import ReactNotification from 'react-notifications-component';
import { Router, Switch } from "react-router-dom";
import "./assets/scss/app.scss";
import Layout from "./components/layout/Layout";
import ScrollToTop from "./components/scroll-to-top/ScrollToTop";
import AdminDashboard from "./pages/admin/dashboard/Dashboard";
import MunicipalityHome from "./pages/admin/municipality-home/MunicipalityHome";
import AdminProfile from "./pages/admin/profile/Profile";
import AdminSetting from "./pages/admin/setting/Setting";
import AllComplaints from "./pages/public/all-complaints/AllComplaints";
import ComplaintRegister from "./pages/public/complaint-register/ComplaintRegister";
import ComplaintStatus from "./pages/public/complaint-status/ComplaintStatus";
import Contact from "./pages/public/contact-us/ContactUs";
import Faq from "./pages/public/faq/Faq";
import ForgetPassword from "./pages/public/forget-password/ForgetPassword";
import HomePage from "./pages/public/home-page/HomePage";
import Login from "./pages/public/login/Login";
import NotAuthorized from "./pages/public/not-authorized/NotAuthorized";
import GlobalPageNotFound from "./pages/public/not-found/GlobalPageNotFound";
import ResetPassword from "./pages/public/reset-password/ResetPassword";
import Complaint from "./pages/user/complaint/Complaint";
import UserDashboard from "./pages/user/dashboard/Dashboard";
import Profile from "./pages/user/profile/Profile";
import UserSetting from "./pages/user/setting/setting";
import Route from "./routes/Route";
import history from "./services/history";

export default function App() {
  return (
    <>
      <ReactNotification />
      <Router history={history}>
        <CssBaseline />
        <Layout>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/complaint-register" component={ComplaintRegister} />
            <Route exact path="/all-complaints" component={AllComplaints} />
            <Route exact path="/complaint-status" component={ComplaintStatus} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/page-not-found" component={GlobalPageNotFound} isWrongLink />
            <Route exact path="/user-not-authorized" component={NotAuthorized} />
            <Route exact path="/reset" component={ResetPassword} />
            <Route exact path="/forget-password" component={ForgetPassword} />
            <Route exact path="/faq" component={Faq} />
            <Route exact path="/user/contact-us" component={Contact} isPrivate />
            <Route exact path="/user/dashboard" component={UserDashboard} isPrivate />
            <Route exact path="/user/setting" component={UserSetting} isPrivate />
            <Route exact path="/user/profile" component={Profile} isPrivate />
            <Route exact path="/user/complaint" component={Complaint} isPrivate />


            {/* Admin Route */}
            <Route exact path="/admin/dashboard" component={AdminDashboard} isPrivate />
            <Route exact path="/admin/municipality-home" component={MunicipalityHome} isPrivate />
            <Route exact path="/admin/setting" component={AdminSetting} isPrivate />
            <Route exact path="/admin/profile" component={AdminProfile} isPrivate />
            <Route component={GlobalPageNotFound} isWrongLink />
          </Switch>
        </Layout>
      </Router>
      <ScrollToTop />
    </>
  );
}
