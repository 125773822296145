import { COMPLAINT_PRIORITY_TYPES, COMPLAINT_TYPES } from "../utils/constants/forms";
import { FISCAL_YEARS, PALIKA_TYPES, PROVINCE_DISTRICT_PALIKA_LIST, USER_ROLES } from "../utils/constants/index";

export const AppMisc = {
  getProvinceName(provinceValue) {
    return provinceValue ? PROVINCE_DISTRICT_PALIKA_LIST.find(obj => obj.value === provinceValue).label : "";
  },

  getDistrictName(districtValue) {
    for (let i = 0; i < PROVINCE_DISTRICT_PALIKA_LIST.length; i++) {
      for (let j = 0; j < PROVINCE_DISTRICT_PALIKA_LIST[i].districts.length; j++) {
        if (PROVINCE_DISTRICT_PALIKA_LIST[i].districts[j].value === districtValue) {
          return PROVINCE_DISTRICT_PALIKA_LIST[i].districts[j].label;
        }
      }
    }
  },

  // TODO: sandeep - refactor the function to minimize loops.
  getMunicipalityName(municipalityValue) {
    for (let i = 0; i < PROVINCE_DISTRICT_PALIKA_LIST.length; i++) {
      for (let j = 0; j < PROVINCE_DISTRICT_PALIKA_LIST[i].districts.length; j++) {
        for (let k = 0; k < PROVINCE_DISTRICT_PALIKA_LIST[i].districts[j].palikas.length; k++) {
          if (PROVINCE_DISTRICT_PALIKA_LIST[i].districts[j].palikas[k].value === municipalityValue) {
            return PROVINCE_DISTRICT_PALIKA_LIST[i].districts[j].palikas[k].label;
          }
        }
      }
    }
  },

  getMunicipalityValueFromLabel(municipalityName) {
    for (let i = 0; i < PROVINCE_DISTRICT_PALIKA_LIST.length; i++) {
      for (let j = 0; j < PROVINCE_DISTRICT_PALIKA_LIST[i].districts.length; j++) {
        for (let k = 0; k < PROVINCE_DISTRICT_PALIKA_LIST[i].districts[j].palikas.length; k++) {
          if (PROVINCE_DISTRICT_PALIKA_LIST[i].districts[j].palikas[k].label === municipalityName) {
            return PROVINCE_DISTRICT_PALIKA_LIST[i].districts[j].palikas[k].value;
          }
        }
      }
    }
  },

  getPalikaTypeName(palikaTypeValue) {
    return palikaTypeValue ? PALIKA_TYPES.find(obj => obj.value === palikaTypeValue).label : "";
  },

  getMunicipalityInfo(municipalityValue) {
    for (let i = 0; i < PROVINCE_DISTRICT_PALIKA_LIST.length; i++) {
      for (let j = 0; j < PROVINCE_DISTRICT_PALIKA_LIST[i].districts.length; j++) {
        for (let k = 0; k < PROVINCE_DISTRICT_PALIKA_LIST[i].districts[j].palikas.length; k++) {
          if (PROVINCE_DISTRICT_PALIKA_LIST[i].districts[j].palikas[k].value === municipalityValue) {
            return {
              municipalityName: PROVINCE_DISTRICT_PALIKA_LIST[i].districts[j].palikas[k].label,
              District: PROVINCE_DISTRICT_PALIKA_LIST[i].districts[j].label,
              Province: PROVINCE_DISTRICT_PALIKA_LIST[i].label,
            }
          }
        }
      }
    }
  },

  getUserRole(userRoleValue) {
    return userRoleValue ? USER_ROLES.find(obj => obj.value === userRoleValue).label : "";
  },

  getDistrictOptions() {
    let districts = [];
    for (let i = 0; i < PROVINCE_DISTRICT_PALIKA_LIST.length; i++) {
      for (let j = 0; j < PROVINCE_DISTRICT_PALIKA_LIST[i].districts.length; j++) {
        districts.push(PROVINCE_DISTRICT_PALIKA_LIST[i].districts[j]);
      }
    }
    return districts;
  },

  getPALIKA_TYPESByDistrict(district) {
    for (let i = 0; i < PROVINCE_DISTRICT_PALIKA_LIST.length; i++) {
      for (let j = 0; j < PROVINCE_DISTRICT_PALIKA_LIST[i].districts.length; j++) {
        if (PROVINCE_DISTRICT_PALIKA_LIST[i].districts[j].value === district) {
          return PROVINCE_DISTRICT_PALIKA_LIST[i].districts[j].palikas;
        }
      }
    }
  },

  getFiscalYearLabel(fiscalYear) {
    return fiscalYear ? FISCAL_YEARS.find(obj => obj.value === fiscalYear).label : "";
  },
  getComplaintTypeLabel(complaintType) {
    return complaintType ? COMPLAINT_TYPES.find(obj => obj.value === complaintType).label : "-";
  },
  getComplaintPriorityLabel(priority) {
    return priority ? COMPLAINT_PRIORITY_TYPES.find(obj => obj.value === priority).label : "-";
  }
};
