import React from "react";
import { AppUtils } from "../../../utils/appUtils";
import { ROLE_MUNICIPALITY_ADMIN } from "../../../utils/constants";
import MunicipalityAdmin from "./helpers/MunicipalityAdmin";
import Representative from "./helpers/Representative";

export default function UserDashboard() {
  const userRole = AppUtils.getUserRole();
  return (
    <>
      {
        userRole === ROLE_MUNICIPALITY_ADMIN ? (
          <MunicipalityAdmin />
        ) : (
            <Representative role={userRole} />
          )
      }
    </>
  );
}
