import { AppBar, Box, Button, Link, Toolbar, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import logo from "../../assets/img/nepal-government.png";
import styles from "./style";

export default function PublicHeader(props) {
  const classes = styles();
  let history = useHistory();

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <img src={logo} alt="जन-आवाज" width="36"></img>
        <Typography variant="h6" className={classes.brand}>
          <Link
            className={classes.link}
            onClick={() => {
              history.push("/");
            }}
          >
            कृष्णपुर नगरपालिका - हेलो मेयर (गुनासो व्यवस्थापन प्रणाली)
          </Link>
        </Typography>
        <Box display="flex" className={classes.menuItems} justifyContent="center" alignItems="center">
          <Link href="/faq" className={classes.navLink}>बारम्बार सोधिने प्रश्नहरु</Link>
          <Button variant="contained" color="primary" onClick={(e) => { history.push("/login"); }}>लग-इन</Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
