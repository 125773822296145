import { OTHER } from "..";

export const COMPLAINT_TYPES = [
  { value: "INFRASTRUCTURE_RELATED", label: "पूर्वाधार विकास सम्बन्धी" },
  { value: "GOVERNMENTAL_VEHICLES_MISUSE", label: "सरकारी सवारी साधनको दुरुपयोग" },
  { value: "IRREGULARITIES_IN_CONSTRUCTION_WORK", label: "निर्माणकार्यमा अनियमितता" },
  { value: "ADMINISTRATIVE_AND_SERVICE_DELIVERY", label: "प्रशासनिक समस्या र सेवा प्रवाह विषयक" },
  { value: "CORRUPTION_CONTROL_RELATED", label: "भ्रष्टाचार नियन्त्रण विषयक" },
  { value: "PRIVATE_SECTOR_WRONG_ACTIVITIES_RELATED", label: "निजी क्षेत्रका अनियमित र गलत क्रियाकलापबारे" },
  { value: "AGRICULTURE_AND_COOPERATIVES_RELATED", label: "कृषि तथा सहकारी सम्बन्धी" },
  { value: "TOURISM_PROMOTION_AND_DEVELOPMENT_RELATED", label: "पर्यटन प्रवर्द्धन र विकास सम्बन्धी" },
  { value: "PUBLIC_ISSUES_OF_DAILY_LIFE", label: "दैनिक जीवनका सार्वजनिक विषयवस्तुसँग सम्बन्धित समस्याहरु" },
  { value: "FOREST_AND_ENVIRONMENT_RELATED", label: "वन तथा वातावरणसँग सम्बन्धित" },
  { value: "HEALTH_AND_EDUCATION_RELATED", label: "स्वास्थ्य र शिक्षा सम्बन्धी" },
  { value: "ENCROACHMENT_OF_PUBLIC_LAND", label: "प्राकृतिक श्रोत / सार्वजनिक जग्गा अतिक्रमण सम्बन्धी" },
  { value: "REVENUE_FRAUD", label: "भन्सार/राजस्व छली" },
  { value: "DRINKING_WATER_RELATED", label: "खानेपानी सम्बन्धित गुनासो" },
  { value: "CITIZENS_CHARTER_RELATED", label: "नागरिक वडापत्र सम्बन्धि गुनासो" },
  { value: "PREACHING_ILLEGAL_RELIGION", label: "गैर कानूनी धर्म प्रचार" },
  { value: "THREAT_RECOVERY_RELATED", label: "धम्कि/असूली धन्दा" },
  { value: "EMPLOYEE_DISCIPLINE_RELATED", label: "कर्मचारीको अनुशासन/आचरण" },
  { value: "BLACK_MARKET_RELATED", label: "कालोबजारी/गुणस्तरहिन बस्तुको बिक्री" },
  { value: "TRAFFIC_POLICE_RELATED", label: "ट्राफिक प्रहरी सम्बन्धि गुनासो" },
  { value: "EMPLOYEE_RELATED", label: "कर्मचारीसंग सम्बन्धित गुनासो" },
  { value: "DRAINAGE_MANAGEMENT_RELATED", label: "ढल व्यवस्थापन सम्बन्धी गुनासो" },
  { value: "ROAD_AND_BRIDGE_RELATED", label: "सडक पुलसंग सम्बन्धित गुनासो" },
  { value: OTHER, label: "अन्य" },
];

export const COMPLAINT_PRIORITY_TYPES = [
  { value: "NORMAL", label: "सामान्य" },
  { value: "IMPORTANT", label: "जरुरी" },
  { value: "VERY_IMPORTANT", label: "अती जरुरी" },
];

export const COMPLAINT_STATUS = [
  { value: "UNASSIGNED", label: "Unassigned/Pending" },
  { value: "IN_PROGRESS", label: "In Progress" },
  { value: "INAPPROPRIATE", label: "Inappropriate/Spam" },
  { value: "CLOSED", label: "Closed" }
];

export const COMPLAINTS_TABLE_COMMON_HEAD_ITEMS = [
  { id: "ticketId", name: "ticketId", label: "टिकट नं.", minWidth: 100 },
  { id: "complaintType", name: "complaintType", label: "गुनासोको प्रकार" },
  { id: "priority", name: "priority", label: "गुनासोको गम्भीरता" },
  { id: "complaintDetails", name: "complaintDetails", label: "गुनासो/उजुरीको संक्षिप्त विवरण" },
  { id: "wardNumber", name: "wardNumber", label: "वडा नं." },
  { id: "createdDate", name: "createdDate", label: "गुनासो दर्ता मिति" },
  { id: "reporterFullName", name: "reporterFullName", label: "निवेदकको नाम" },
];

export const FEEDBACK_SUBJECTS = [
  {value: "CLIENT_SERVICE_RELATED", label: "सामान्य ग्राहक सेवा"},
  {value: "SUGGESTIONS", label: "सुझावहरु"},
  {value: "SOFTWARE_RELATED", label:"सफ्टवेर सम्बन्धि जानकारी"},
];
