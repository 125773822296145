import { Home, ContactPhone } from "@material-ui/icons";
import React from "react";

export const JA_ADMIN_SIDEBAR_LINKS = [
  {
    id: 0,
    label: "Home",
    link: "dashboard",
    iconComponent: <Home fontSize="small" />
  }
];

export const JA_MUNICIPALITY_USER_SIDEBAR_LINKS = [
  {
    id: 0,
    label: "गृहपृष्ठ",
    link: "dashboard",
    iconComponent: <Home fontSize="small" />
  },
  {
    id: 1,
    label: "समस्या रिपोर्ट गर्नुहोस्",
    link: "contact-us",
    iconComponent: <ContactPhone fontSize="small" />
  }
];

export const JA_USER_SIDEBAR_LINKS = [
  {
    id: 0,
    label: "गृहपृष्ठ",
    link: "dashboard",
    iconComponent: <Home fontSize="small" />
  },
  {
    id: 1,
    label: "समस्या रिपोर्ट गर्नुहोस्",
    link: "contact-us",
    iconComponent: <ContactPhone fontSize="small" />
  }
];
