import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@material-ui/core";
import React from "react";
import { AppMisc } from "../../../../misc/appMisc";
import { NO_RECORDS_FOUND } from "../../../../utils/constants";
import { DateUtils } from "../../../../utils/dateUtils";
import styles from "./style";

export default function ComplaintsTable(props) {
  const classes = styles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table size="small" stickyHeader>
          <TableHead classes={{ root: "align-center" }}>
            <TableRow>
              {props.columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: "align-center" }}>
            {
              props.rows.length ? (
                props.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  return (
                    <TableRow key={row.id} hover>
                      {props.columns.map((column) => {
                        let value = row[column.name];
                        if (column.name === "complaintType") {
                          value = AppMisc.getComplaintTypeLabel(value);
                        } else if (column.name === "priority") {
                          value = AppMisc.getComplaintPriorityLabel(value);
                        } else if (column.name === "createdDate") {
                          value = DateUtils.getDateFromMilliseconds(value);
                        }
                        return (
                          <TableCell key={column.id} className={classes.bodyCell}>
                            {
                              column.name === "ticketId" ? <a target='_blank' rel="noopener noreferrer" href={`complaint?id=${row.id}`}>{value ? value : "-"}</a> : value
                            }
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              ) : (
                  <TableRow>
                    <TableCell colSpan={props.columns.length} size="medium">{NO_RECORDS_FOUND}</TableCell>
                  </TableRow>
                )
            }

          </TableBody>
        </Table>
      </TableContainer>
      {
        props.rows.length !== 0 &&
        <TablePagination
          rowsPerPageOptions={[10, 25, 100, 250]}
          component="div"
          count={props.rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      }
    </Paper>
  );
}
