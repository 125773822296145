import { Box, Button, Container, Grid, Paper, TextField, Tooltip, Typography } from "@material-ui/core";
import { Help } from "@material-ui/icons";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import JA, { API_URL } from "../../../api/api";
import AddAlertMessage from "../../../components/alert/Alert";
import ComplaintDetails from "../../../components/complaint-details/ComplaintDetails";
import PublicHeader from "../../../components/public-header/PublicHeader";
import { REQUIRED_FIELD, SOMETHING_WENT_WRONG } from "../../../utils/constants";
import styles from "./style";

export default function ComplaintStatus(props) {
  const { register, errors, handleSubmit } = useForm();
  const [complaintDetail, setComplaintDetail] = useState({})
  const classes = styles();

  const getComplaintDetails = data => {
    setComplaintDetail({});
    JA.get(API_URL.complaintDetail + "/ticket-id?ticketId=" + data.ticketNumber)
      .then(response => {
        if (response.data.type === "error") {
          AddAlertMessage({ type: response.data.type, message: response.data.message });
        } else {
          setComplaintDetail(response.data);
        }
      })
      .catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  };

  return (
    <Container maxWidth="xl" className={classes.root}>
      <PublicHeader />
      <Paper className={classes.complaintSearchContainer}>
        <Box display="flex" alignItems="center" className="border-bottom-heading" mb={2}>
          <Typography>आफ्नो गुनासोको स्थिती थाहा पाउनुहोस्</Typography>
          <Tooltip title="आफ्नो गुनासोको स्थिती थाहा पाउन, गुनासो दर्ता गर्दाको दर्ता नम्बर उल्लेख गर्नुहोस्।" placement="top" arrow>
            <Help className={classes.helpIcon} fontSize="small" />
          </Tooltip>
        </Box>
        <form onSubmit={handleSubmit(getComplaintDetails)}>
          <Grid container spacing={2} alignItems="center" className={classes.row}>
            <Grid item xs={5}>
              <TextField
                label="टिकट/ट्र्याक नम्बर"
                type="text"
                size="small"
                variant="outlined"
                name="ticketNumber"
                inputRef={register({
                  required: true
                })}
                fullWidth
              />
              {errors.ticketNumber && <span className="error-message">{REQUIRED_FIELD}</span>}
            </Grid>
            <Grid item xs={2}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
              >
                विवरण हेर्नुहोस्
              </Button>
            </Grid>
            <Grid item xs={5}></Grid>
          </Grid>
        </form>
      </Paper>
      {
        JSON.stringify(complaintDetail) !== "{}" &&
        <Paper className={classes.complaintDetails}>
          <Box mb={1}>
            <Typography variant="h6">गुनासो/उजुरी विवरण</Typography>
          </Box>
          <ComplaintDetails data={complaintDetail} />
        </Paper>
      }
    </Container>
  );
}
