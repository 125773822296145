import { Grid, TextField, Typography, Box, FormLabel, Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CustomSelect from "../../../components/custom-select/CustomSelect";
import CustomModal from "../../../components/modal/CustomModal";
import { REQUIRED_FIELD, FILE_SIZE_EXCEED } from "../../../utils/constants";
import { COMPLAINT_STATUS } from "../../../utils/constants/forms";
import styles from "../style";
import { Help } from "@material-ui/icons";

export default function UpdateComplaintStatusModal({ showUpdateComplaintStatusModal, onModalClose, onSubmit, ...props }) {
  const classes = styles();
  const { register, handleSubmit, reset, setValue, errors } = useForm();
  const [attachments, setAttachments] = useState([]);
  const [filesValid, setFilesValid] = useState(true);

  useEffect(() => {
    register({ name: "status" }, { required: true });
  }, [register]);

  useEffect(() => {
    setAttachments([]);
  }, [showUpdateComplaintStatusModal]);

  const closeUpdateComplaintStatusModal = () => {
    reset();
    onModalClose();
  }

  const handleCustomSelectChange = (value, name) => {
    setValue(name, value);
  };

  function handleDocumentsChange(e) {
    let files = e.target.files;
    let filesList = [];
    let allFileSize = 0;
    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        filesList.push(files[i]);
        allFileSize += files[i].size;
      }
    }
    setFilesValid(allFileSize < (10 * 1024 * 1024));
    setAttachments(filesList);
  }

  const statusDetailsSubmit = data => {
    data.attachments = attachments;
    onSubmit(data);
  }

  return (
    <CustomModal
      title="गुनासो/उजुरी मा भईरहेको कार्यहरुको विवरण अपडेट गर्नुहोस्।"
      showModal={showUpdateComplaintStatusModal}
      onModalSubmit={handleSubmit(statusDetailsSubmit)}
      onModalClose={closeUpdateComplaintStatusModal}
      submitButtonDisabled={!filesValid}
      maxWidth="lg"
    >
      <Grid container spacing={2} alignItems="flex-end" className={classes.gridRow}>
        <Grid item xs>
          <TextField
            label="शीर्षक"
            size="small"
            variant="outlined"
            name="title"
            defaultValue={props.defaultValues.title}
            inputRef={register({
              required: true
            })}
            fullWidth
          />
          {errors.title && <span className="error-message">{REQUIRED_FIELD}</span>}
        </Grid>
        <Grid item xs>
          <CustomSelect
            label="स्थिति"
            size="small"
            name="status"
            value={props.defaultValues.status}
            variant="outlined"
            options={COMPLAINT_STATUS}
            onChange={handleCustomSelectChange}
            fullWidth
            disabledOptionSelectable
          />
          {errors.status && <span className="error-message">{REQUIRED_FIELD}</span>}
        </Grid>
        <Grid item xs>
          <Box mb={1} display="flex" alignItems="center">
            <Typography variant="caption">सम्वन्धित कागजातहरु (छ भने)</Typography>
            <Tooltip title="कृपया फाईल चयन गर्दा 10MB भन्दा कम साइजको फाइल चयन गर्नहुन अनुरोध छ।" placement="top" arrow>
              <Help className={classes.helpIcon} fontSize="small" />
            </Tooltip>
          </Box>
          <Box className={classes.filesContainer}>
            <input
              type="file"
              name="documents"
              accept="image/*,application/pdf,.doc,.ppt,.pptx,.txt,.docx,.xlsx,.xls"
              onChange={handleDocumentsChange}
              multiple
            />
          </Box>
          {!filesValid && <span className="error-message">{FILE_SIZE_EXCEED}</span>}
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" className={classes.gridRow}>
        <Grid item xs>
          <TextField
            label="विवरण"
            size="small"
            variant="outlined"
            name="description"
            defaultValue={props.defaultValues.description}
            inputRef={register({
              required: true
            })}
            rows={5}
            fullWidth
            multiline
          />
          {errors.description && <span className="error-message">{REQUIRED_FIELD}</span>}
        </Grid>
      </Grid>
    </CustomModal>
  )
}