import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  row: {
    marginBottom: theme.spacing(1)
  },
  cardContainer: {
    flex: "0 0 33.33%"
  }
}));
export default styles;
