import { Box, Container, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import JA, { API_URL } from "../../../api/api";
import AddAlertMessage from "../../../components/alert/Alert";
import ComplaintsList from "../../../components/complaints-list/ComplaintsList";
import PublicHeader from "../../../components/public-header/PublicHeader";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
import styles from "./style";

export default function AllComplaints(props) {
  const [complaintsList, setComplaintsList] = useState();
  const classes = styles();

  useEffect(() => {
    getComplaints();
  }, [])

  const getComplaints = () => {
    JA.get(API_URL.complaintDetail)
      .then(response => {
        setComplaintsList(response.data);
      })
      .catch(error => {
        AddAlertMessage({ type: "error", message: SOMETHING_WENT_WRONG });
      });
  };

  return (
    <Container maxWidth="xl" className={classes.root}>
      <PublicHeader />
      <Box textAlign="center" mb={2}>
        <Typography>सार्वजनिक गरिएका सबै गुनासो/उजुरीहरु</Typography>
      </Box>
      <Box className={classes.publicComplaintsCardContainer}>
        <ComplaintsList complaints={complaintsList} />
      </Box>

    </Container>
  );
}
